.home-extensions {
  background-color: #ffffff;
  box-shadow: 1px 2px 1px #d1d8de;
  border-radius: 2px;
  margin-top: 1.5rem;
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;

  .home-extensions-title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 0.8rem;
    text-align: center;
  }

  .home-extensions-button-wrapper {
    margin-top: 2rem;
  }

  .home-extensions-extensions {
    .home-extensions-extensions-row {
      display: flex;
      margin: 5px;
    }

    img {
      margin: 4px;
      border: 0.5px solid #dfe6ed;
      max-width: 100%;
      max-height: 100%;
      height: 90px;
    }
  }
}
