@import "~bootstrap/scss/mixins";

@import "~bootstrap/scss/functions";

@import "~bootstrap/scss/variables";

#footer {
    margin-top: 2rem;
    color: $white;
    display: flex;
    justify-content: center;
}