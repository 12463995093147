/* The following block can be included in a custom.scss */

/* make the customizations */
// $theme-colors: (
//   "info": tomato,
//   "danger": teal,
// );

@font-face {
  font-family: "Siemens Sans";
  src: url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemenssans-roman-webfont.eot");
  src: url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemenssans-roman-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemenssans-roman-webfont.woff")
      format("woff"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemenssans-roman-webfont.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Siemens Sans";
  src: url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemenssans-italic-webfont.eot");
  src: url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemenssans-italic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemenssans-italic-webfont.woff")
      format("woff"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemenssans-italic-webfont.ttf")
      format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Siemens Sans";
  src: url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemenssans-bold-webfont.eot");
  src: url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemenssans-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemenssans-bold-webfont.woff")
      format("woff"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemenssans-bold-webfont.ttf")
      format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Siemens Sans";
  src: url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemenssans-bolditalic-webfont.eot");
  src: url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemenssans-bolditalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemenssans-bolditalic-webfont.woff")
      format("woff"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemenssans-bolditalic-webfont.ttf")
      format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Siemens Sans black";
  src: url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemenssans-black-webfont.eot");
  src: url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemenssans-black-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemenssans-black-webfont.woff")
      format("woff"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemenssans-black-webfont.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Siemens Sans Black";
  src: url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemenssans-blackitalic-webfont.eot");
  src: url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemenssans-blackitalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemenssans-blackitalic-webfont.woff")
      format("woff"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemenssans-blackitalic-webfont.ttf")
      format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Siemens Serif";
  src: url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-roman-webfont.eot");
  src: url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-roman-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-roman-webfont.woff")
      format("woff"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-roman-webfont.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Siemens Serif";
  src: url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-italic-webfont.eot");
  src: url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-italic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-italic-webfont.woff")
      format("woff"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-italic-webfont.ttf")
      format("truetype"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-italic-webfont.svg#icon")
      format("svg");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Siemens Serif";
  src: url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-bold-webfont.eot");
  src: url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-bold-webfont.woff")
      format("woff"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-bold-webfont.ttf")
      format("truetype"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-bold-webfont.svg#icon")
      format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Siemens Serif";
  src: url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-bolditalic-webfont.eot");
  src: url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-bolditalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-bolditalic-webfont.woff")
      format("woff"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-bolditalic-webfont.ttf")
      format("truetype"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-bolditalic-webfont.svg#icon")
      format("svg");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Siemens Serif";
  src: url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-semibold-webfont.eot");
  src: url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-semibold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-semibold-webfont.woff")
      format("woff"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-semibold-webfont.ttf")
      format("truetype"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-semibold-webfont.svg#icon")
      format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Siemens Serif";
  src: url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-semibolditalic-webfont.eot");
  src: url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-semibolditalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-semibolditalic-webfont.woff")
      format("woff"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-semibolditalic-webfont.ttf")
      format("truetype"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemensserif-semibolditalic-webfont.svg#icon")
      format("svg");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Siemens Webicons";
  src: url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemens-webicons-webfont.eot?avcggw");
  src: url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemens-webicons-webfont.eot?avcggw#iefix")
      format("embedded-opentype"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemens-webicons-webfont.ttf?avcggw")
      format("truetype"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemens-webicons-webfont.woff?avcggw")
      format("woff"),
    url("//polarion.plm.automation.siemens.com/hubfs/Siemens/Fonts/siemens-webicons-webfont.svg?avcggw#siemens-webicons-webfont")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

// [class^="icon-"],
// [class*=" icon-"] {
//   font-family: "Siemens Webicons" !important;
//   font-style: normal;
//   font-weight: normal;
//   font-variant: normal;
//   text-transform: none;
//   line-height: 1;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// [class*="icon-"] {
//   display: inline-block;
//   vertical-align: middle;
// }

$font-family-sans-serif:
    // Cross-platform generic font family (default user interface font)
  "Siemens Sans", system-ui,
  // Safari for macOS and iOS (San Francisco)
  -apple-system,
  // Windows
  "Segoe UI",
  // Android
  Roboto,
  // Basic web fallback
  "Helvetica Neue",
  Arial,
  // Linux
  "Noto Sans",
  "Liberation Sans",
  // Sans serif fallback
  sans-serif,
  // Emoji fonts
  "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
