$primary: #2387aa;
$warning: #faa50a;
$body-bg: #fff;

@media only screen and (max-width: 768px) {
  #root .container {
    padding: 0px 20px;
  }

  .whiteBox {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

body {
  padding: 0;
  margin: 0;
  border: 0;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: 400;
  color: #333;
  background-color: #000028 !important;
}

.whiteBox {
  background-color: #ffffff;
  margin-top: 1.5rem;
  border-radius: 16px,
}

button,
a.button {
  &.cta {
    color: #000028 !important;
    background: linear-gradient(90deg, #00ffb9 0%, #00e6dc 100%);
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
    border-radius: 0px;
    text-decoration: none;
  }
}

.whiteForm {
  .form-control {
    background: white;
    border-radius: 4px;
    line-height: 1.5rem;
    margin-bottom: 1.2rem;
  }

  .required-bullet {
    color: #af235f;
    font-size: 20px;
    font-weight: bold;
    margin-left: 0.3rem;
  }

  input.error {
    border: 1px solid #af235f;
  }

  div.error input[type="checkbox"] {
    border: 1px solid #af235f;
  }

  input[type="checkbox"] {
    width: 1.2rem;
    height: 1.2rem;
  }

  label {
    font-weight: bold;
  }

  .checkbox-with-text {
    .checkbox-with-text-wrapper {
      display: flex;
      margin-bottom: 1rem;
    }
    .checkbox-with-text-label-text {
      margin-left: 1rem;
      font-weight: normal;
    }
  }

  .error-message {
    background-color: #af235f;
    color: white;
    padding: 5px;
    margin-left: 10px;
  }
}


@import "fonts.scss";
@import "~bootstrap/scss/bootstrap";
@import "~react-bootstrap-country-select/dist/react-bootstrap-country-select.css";
